import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Button, Row, Col, Alert,DropdownButton,Dropdown } from 'react-bootstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { cap } from '../params'
import '../style/style.css'
import '../style/gold3.css'
import '../style/sty.scss'
import logoig from '../assets/img/nasta/logoig.svg'
import burung from '../assets/img/nasta/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import AOS from 'aos';
import { gambar, pw } from '../params'
import { Timer } from '../components/timer'
import post from '../params/post'
import getData from '../params/getdata'

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import covid from '../assets/img/nasta/covid.png'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
let abu = "#909090"
let cmain = '#C4964B'
let black = 'rgb(38,38,38)'

let id = 'stella-raymundus'
let inisial_co = 'Raymundus'
let inisial_ce = 'Stella'
let lengkap_co = 'Raymundus Parikesit'
let lengkap_ce = 'Felisia Stella Harisa'
let bapak_co = 'Bpk. Robertus Purwoko W'
let ibu_co = 'Ibu Monica Pingkan Jacob'
let bapak_ce = "Bpk. Fransiskus Sapto Budihardjo "
let ibu_ce = "Ibu Margaretha Ignatia Harilastuti"
let ig_co = "raymundusparikesit"
let ig_ce = "felisiastellaa"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "02/06/2021"

let modal = pw(id, "modal.jpg")
let openlogo = pw(id, "logo.png")

let gmaps = "https://goo.gl/maps/Y749T7TirFy7Rwag6"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=MG92YjEwaGhyZ2poNnBiNG5nNGF0aThlaTcgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.795333881315!2d106.81503241476949!3d-6.29060939544711!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f21d94f0df35%3A0xe8349fcba6323e00!2sOmah%20Pawon!5e0!3m2!1sid!2sid!4v1609945959475!5m2!1sid!2sid" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>`
gmaps1 = gmaps1.split('"')[1]


export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 0,
            friend:0,
            jumlah:0,
            showrek:true
        }
    }

    async componentDidMount() {
        try {
            let comment = await getData(`kepada:"${id}"`)
            let jumlah =0
            comment.map(v=>{
                jumlah=jumlah+parseInt(v.hadir)
            })
            AOS.init({
                duration: 2000
            });
            console.log(jumlah)
            this.setState({jumlah:jumlah})
        }catch(e){
            console.log(e)
        }
    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    dropdown = () => {
        let {friend} = this.state
        let item = []
        for (let index = 1; index < 3; index++) {
            item[index] = (<Dropdown.Item onClick={() => {
                this.setState({ friend: index })
            }}>{index}</Dropdown.Item>)
        }
        item.push(
            <Dropdown.Item onClick={() => {
                this.setState({ friend: 0 })
            }}>Not Attend</Dropdown.Item>
        )
        return (
            <>
                <Item>
                    <p style={{ fontSize: '16px' }} className="mb-0 w-100 text-center">
                        How many people you coming with?
                    </p>
                    <DropdownButton id="dropdown-basic-button" title={friend == null ? '1' : friend}>
                        {
                            item
                        }
                    </DropdownButton>
                </Item>
            </>
        )
    }
    render() {
        let { hadir, days, hours, minutes, showrek, hide, submitted, err, sesi,jumlah } = this.state
        let slider = []
        let slide = [
            "1.JPG",
            "10.jpg",
            "3.jfif",
            "3.JPG",
            "4.JPG",
            "5.JPG",
            "6.jpg",
            "8.jfif",
            "8.jpg",
            "9.jpg",
        ]
        slide.map(v => {
            slider.push(pw(id, v))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slider[0]}
                    url={`https://undang.in/${id}`}
                >
                    <link rel="preconnect" href="https://fonts.gstatic.com"></link>
                    <link href="https://fonts.googleapis.com/css2?family=Yellowtail&display=swap" rel="stylesheet"></link>

                    <link href="https://fonts.googleapis.com/css2?family=Allura&display=swap" rel="stylesheet"></link>
                </Helm>

                <div id='gold5' style={{
                    backgroundImage: `none`,
                    backgroundColor: '#E7E6E2',
                    backgroundSize: 'cover', backgroundPosition: 'center'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent',

                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundImage: `url('${modal}')`,
                            zIndex: 1,

                        }}>
                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 '
                                        src={openlogo}  />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`} style={{ marginTop: '0' }}>
                                        <br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: 'white' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>

                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent', position: 'relative' }}>
                                <Container fluid id='g3-header' className='position-fixed' style={{
                                    top: 0, left: 0, zIndex: '0',
                                    backgroundImage: `url("${pw(id, "bg.jpg")}")`,
                                }}>


                                </Container>
                                <Container className="dinny px-3 pt-5 position-relative">
                                    <Item>
                                        <p className="fs16 text-center px-3" style={{ color: cmain,
                                           }}>
                                       <b>
                                       Demikianlah mereka bukan lagi dua, melainkan satu. Karena itu, apa yang telah dipersatukan Allah, tidak boleh diceraikan manusia
                                    <br /><br />(Matius 19:6)
                                       </b>
                                        </p>
                                    </Item>
                                    <div className="embed-responsive embed-responsive-4by3 mb-3 p-3">
                                        <iframe className="embed-responsive-item"
                                            src={pw(id, "video.mp4")}></iframe>
                                    </div>

                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={pw("irli-rizki", "daun.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>

                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br />

                                        </p>

                                    </Item>
                                    {/* <Item>
                                        <Col xs={8} md={4}>
                                            <img src={pw(id, "name.png")} className="w-100 img-fluid" />
                                        </Col>
                                    </Item> */}
                                    <Item>
                                        <Col xs={8} md={4}>
                                            <img src={pw(id, "thumb.jpg")}
                                                style={{ borderRadius: '10px' }}
                                                className="w-100 img-fluid" />
                                        </Col>
                                    </Item>
                                    <p style={{ fontSize: '16px', color: 'black' }} className="w-100 text-center pt-3">
                                                            “Distance means nothing, when someone means everything”
                                                            </p>
                                </Container>

                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: 'Allura, cursive' }} >
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={foto_ce} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{
                                                            fontSize: '36px'
                                                            , color: cmain,
                                                            fontFamily: 'Allura, cursive'
                                                        }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center'
                                                        style={{ fontSize: '18px', color: cmain }}>
                                                        <b>Putri dari :</b><br />
                                                        {bapak_ce}  <br />
                                                        &<br />
                                                        {ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-black.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>

                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: 'Allura, cursive' }} >
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={foto_co} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center " style={{
                                                        fontSize: '36px',
                                                        fontFamily: 'Allura, cursive',
                                                        color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{
                                                        fontSize: '18px', color: cmain
                                                    }}>
                                                        <b>Putra dari:</b><br />
                                                        {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={pw("asset", "logoig-black.svg")} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>

                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: cmain,position:'relative' }} >
                                    <Item>
                                        <p className="fs16" >
                                            Yang Akan dilaksanakan pada:
                                        </p>
                                    </Item>

                                    <Item>
                                        <p className="fs20 col-sm-4 px-2" style={{ color: cmain ,position:'relative'}}>
                                            <b>Pemberkatan Pernikahan</b><br />
                                            <span className="cblack fs16">
                                                <b>
                                                    Sabtu, 06 Februari 2021
                                                </b><br />
                                                09.00-10.00 WIB<br />
                                                <b>Gereja Santo Mikael Kranji</b>
                                            </span>
                                            <Item>
                                    <Button style={{ backgroundColor: cmain }} onClick={() => {
                                                window.location.href = 'https://bit.ly/STEywithRAY'
                                            }}>
                                                Live Streaming
                                            </Button>
                                    </Item>
                                        </p>
                                        <p className="fs20 col-sm-4 px-2" style={{ color: cmain,position:'relative' }}>
                                            <b>Resepsi</b><br />
                                            <span className="cblack fs16">
                                                <b>
                                                    Sabtu, 06 Februari 2021
                                                </b><br />
                                                11.30-13.30 WIB<br />
                                                RSVP Family Only (before full capacity)
                                                 </span>


                                        </p>

                                    </Item>

                                   
                                    <Item>
                                        <p className="fs16 pt-3 position-relative" style={{ color: cmain }}>
                                            <b>Omah Pawon</b> <br />
                                            Jl. Ampera Raya No.2, RT.2/RW.2, Cilandak Tim., 
                                            Kec. Ps. Minggu, Kota Jakarta Selatan, 
                                            Daerah Khusus Ibukota Jakarta 12560
                                        </p>
                                    </Item>

                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px',
                                                backgroundColor: 'rgba(255,255,255,0)'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Row  className="px-3">
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Row>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px',
                                                backgroundColor: 'rgba(255,255,255,0)'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Row className="px-3">
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Row>
                                        </Col>
                                    </Item>

                                </Container>
                                <Container>
                                <Item>
                                                    <h1 className="w-100 text-center pt-3" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain,
                                                        marginBottom:'-50px',
                                                        position:'relative'
                                                    }}>
                                                        Countdown
                                                        
                                                    </h1>
                                                    
                                                </Item>
                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />

                                <Container className="text-center dinny">
                                    <Item>
                                        <Col className="py-3 px-sm-5" style={{
                                            border: `2px solid ${cmain}`, borderRadius: '10px',
                                            backgroundColor: 'rgba(255,255,255,0.6)'
                                        }}>
                                            <h1 className="fs30"
                                                style={{


                                                    color: cmain
                                                }}>
                                                Protokol Kesehatan
                          </h1>
                                            <Item>
                                                <Col xs={12}>
                                                    <p className="cblack text-center w-100 fs16">
                                                        Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'mask.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Gunakan Masker
                        </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'distance.svg')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Jaga Jarak
                      </p>
                                                    </Row>
                                                </Col>
                                                <Col xs={6} sm={4}>
                                                    <img src={pw('asset', 'salaman.png')} className="w-100 img-fluid p-sm-4" />
                                                    <Row>
                                                        <p className="cblack text-center w-100 fs16">
                                                            Cukup Bersalaman tanpa Bersentuhan
                      </p>
                                                    </Row>
                                                </Col>
                                            </Item>
                                        </Col>
                                    </Item>

                                    <Item>
                                        <Col>
                                        </Col>

                                    </Item>

                                </Container>


                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>
                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: 'white' }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14' style={{ fontFamily: "Yellowtail, cursive", color: cmain }}>
                                                            “And above all these put on love, which binds everything together in perfect harmony” Colossians 3:14
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>

                                <Container id='sectiongold58' >

                                    <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                        <Item>
                                            <Col xs={6} sm={2}>
                                                <img src={pw("irli-rizki", "daun.png")} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100 p-2' />
                                            </Col>
                                        </Item>
                                        <Item>
                                            <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                <Item>
                                                    <h1 className="w-100 text-center mb-0" style={{
                                                        fontFamily: '"Marck Script", cursive',
                                                        color: cmain
                                                    }}>
                                                        Send Your Wishes
                                                        
                                                    </h1>
                                                    <p className="w-100 text-center" style={{color:'black',fontSize:'16px'}}>
                                                        Kirimkan Ucapan
                                                    </p>
                                                    
                                                </Item>
                                                <Item>
                                                    <form className="col-12 w-100">
                                                        <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                        <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                        
                                                        <Item>
                                                            <Col xs={12} className=''>
                                                                {
                                                                    submitted == true ? (
                                                                        <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                            Pesan anda sudah disampaikan
                                                                        </Alert>) : (submitted === false ? (
                                                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                {
                                                                                    err.map(val => {
                                                                                        return (
                                                                                            <li>{val}</li>
                                                                                        )
                                                                                    })
                                                                                }

                                                                            </Alert>
                                                                        ) : false)
                                                                }

                                                            </Col>
                                                        </Item>
                                                        <Item>
                                                            <div className='col-6 button rounded btn'
                                                                onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                        </Item>
                                                    </form>
                                                </Item>
                                            </div>
                                        </Item>
                                    </div>
                                </Container>
                                <Container fluid style={{ backgroundColor: cmain, color: 'white',position:'relative' }} className="py-3 mt-3">
                                <Item>
                                <Button style={{backgroundColor:cmain}} onClick={()=>{
                                                        this.setState({showrek:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'22px'}}>
                                                            Send Gift <img src='https://www.flaticon.com/svg/static/icons/svg/837/837891.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                </Item>
                                    {
                                        showrek?(<>
                                    <Item>
                                    <p className="text-center mx-5 py-3" style={{fontSize:'16px'}}>
                                    Tanpa mengurangi rasa hormat, bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih untuk pengantin, dapat melalui:
                                    </p>
                                    </Item>
                                    <Item>
                                        
                                        <div className="p-3 px-5 col-md-4 col-12 my-2" style={{backgroundColor:'white'}}>
                                        <Item>
                                            <img src={pw(id,"bca.jpg")} style={{width:'200px',height:'200px'}} className="m-2"/>
                                        </Item>
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px',color:cmain}}>
                                                <b>Account Number 7300166886</b><br/>
                                                Bank BCA<br/>
                                                a.n. {lengkap_ce}
                                            </p>
                                            </Item>
                                            <Item>
                                            <CopyToClipboard text="7300166886">
                                                    <div className="position-relative">
                                                    
                                                    <Button style={{backgroundColor:cmain}} onClick={()=>{
                                                        this.setState({copy:true})
                                                    }}>
                                                        <Item>
                                                            <p className="px-2 mb-0" style={{fontSize:'14px',color:'white'}}>
                                                            {this.state.copy?'Copied to Clipboard':'Copy Account Number'} <img src='https://www.flaticon.com/svg/static/icons/svg/926/926768.svg' style={{width:'25px',height:'25px',marginLeft:'10px'}}/>
                                                        
                                                            </p>
                                                            </Item>
                                                    </Button>
                                                    
                                                   
                                                    </div>
                                                    </CopyToClipboard>
                                                </Item>

                                            </div>
                                    </Item>
                                   
                                    {/* <Item>
                                        <div className="p-3 px-5 col-md-4 col-12 my-2" style={{backgroundColor:'white'}}>
                                        
                                            <Item>
                                            <p className="text-center" style={{fontSize:'16px',color:cmain}}>
                                            <b>Address :</b> <br/>
                                            Jl. Kecapi 1 no. 82 RT009/05 Jagakarsa Jakarta Selatan 12620
                                            </p>
                                            </Item>
                                            
                                            </div>
                                    </Item> */}
</>):false}
                                    </Container>
                                

                                <Foot ig={pw("asset","logoig-cream.svg")} dark />
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

