import axios from 'axios'

const getData=async(data)=>{
        let hasil = await axios({
            url: `https://ucapan.undang.in/https://ucapan.undang.in/api/rest.php?function=get_all&kepada="${data}"`,
            method: 'get',
        })
          return hasil.data
}
export default getData
